import * as React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
    return (
        <header className="flex px-2 items-center mb-8">
            <Link to="/">
                <div className="w-20 h-20 md:w-28 md:h-28">
                    <StaticImage
                        src="../images/logo.png"
                        alt="ThePadOnWheels"
                        layout="fullWidth"
                        placeholder="dominantColor"
                        loading="eager"
                    />
                </div>
            </Link>
            <nav className="flex-grow flex justify-end h-full font-medium md:text-xl">
                <Link className="px-2" to="/">
                    Home
                </Link>
                <Link className="px-2" to="#about">
                    About
                </Link>
                <Link className="px-2" to="#contact">
                    Contact
                </Link>
            </nav>
        </header>
    )
}

export default Header
