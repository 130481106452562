import * as React from "react"

const CarCard = ({ title, imageURL, distanceTraveled, placesVisited }) => (
    <div className="flex items-center mb-3 md:w-1/2 md:mb-6 xl:w-1/4">
        <div className="w-14 h-14 rounded-full overflow-hidden mr-4">
            <img
                loading="lazy"
                src={imageURL}
                alt={`${title} car of ThePadOnWheels`}
                className="w-full h-full object-cover"
            />
        </div>
        <div>
            <h4 className="font-medium text-sm mb-1 md:text-lg">{title}</h4>
            <p className="text-xs md:text-sm">
                Distance Traveled -{" "}
                <span className="font-light">{distanceTraveled} KM</span>
            </p>
        </div>
    </div>
)

export default CarCard
