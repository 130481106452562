import * as React from "react"

import { FiInstagram, FiMail } from "react-icons/fi"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import SEO from "../components/seo"
import Layout from "../components/layout"
import PeopleCard from "../components/cards/people"
import CarCard from "../components/cards/car"
import Instagram from "../components/cards/instagram"

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Discovering India" />
            <div className="mb-8 md:mb-10">
                <div className="flex justify-center mb-4">
                    <h1 className="font-handwritten text-2xl md:text-4xl">
                        It's the journey that matters
                    </h1>
                </div>
                <div className="text-lg md:text-2xl">
                    <p>
                        <span className="font-semibold">21,200+</span> KMs
                    </p>
                    <p>
                        <span className="font-semibold">15</span> States
                    </p>
                    <p>
                        Last Spotted in{" "}
                        <span className="font-semibold">Gokarna</span>
                    </p>
                </div>
            </div>
            <div className="mb-8 md:mb-10" id="about">
                <h2 className="text-4xl font-semibold mb-5">About</h2>
                <div className="mb-6 md:text-lg lg:w-1/2">
                    <p className="mb-2">
                        We're the four impulsive lads behind The Pad On Wheels.
                        We came out of the closet as hodophiles back in the year
                        2019 when we roamed around Kerala across multiple
                        destinations.
                    </p>
                    <p className="mb-2">
                        Ever since then, road trips have been our thing. We've
                        ridden and drove the length and breadth of the country
                        and we like to share stories that define Indian
                        destinations.
                    </p>
                    <p>
                        Most of our content right now is on{" "}
                        <OutboundLink
                            className="text-orange-800"
                            href="https://www.instagram.com/thepadonwheels/"
                            target="_blank"
                        >
                            Instagram
                        </OutboundLink>
                        , check out what we've been up to. {`\u{1F603}`}
                    </p>
                </div>
                <div className="mb-12">
                    <h3 className="text-2xl font-medium mb-5">People</h3>
                    <div className="md:flex md:flex-wrap lg:w-2/3 xl:w-1/2">
                        <PeopleCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659430384/thepadonwheels/people/AjamalKhan.jpg"
                            title="Ajamal Khan"
                            subtitle="The Geek"
                        />
                        <PeopleCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659528036/thepadonwheels/people/AnujKrGupta.jpg"
                            title="Anuj Gupta"
                            subtitle="The OPS Guy"
                        />
                        <PeopleCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659515785/thepadonwheels/people/AzizHasan.jpg"
                            title="Aziz Hasan"
                            subtitle="The Hustler"
                        />
                        <PeopleCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659516126/thepadonwheels/people/ViplavSingh.jpg"
                            title="Viplav Singh"
                            subtitle="The Writer"
                        />
                    </div>
                </div>
                <div className="mb-12">
                    <h3 className="text-2xl font-medium mb-5">Wheels</h3>
                    <div className="md:flex md:flex-wrap">
                        <CarCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659432600/thepadonwheels/cars/EcoSport%20White.jpg"
                            title="Ford EcoSport (White)"
                            distanceTraveled={10000}
                        />
                        <CarCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659447709/thepadonwheels/cars/EcoSport%20Black.jpg"
                            title="Ford EcoSport (Black)"
                            distanceTraveled={2000}
                        />
                        <CarCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659515243/thepadonwheels/cars/Maruti%20Suzuki%20Brezza.jpg"
                            title="Maruti Suzuki Brezza"
                            distanceTraveled={1800}
                        />
                        <CarCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1659514645/thepadonwheels/cars/Mahindra%20Thar.jpg"
                            title="Mahindra Thar"
                            distanceTraveled={400}
                        />
                        <CarCard
                            imageURL="https://res.cloudinary.com/ajamalkhan/image/upload/c_scale,f_auto,w_100/v1663051888/thepadonwheels/cars/Mahindra%20Marazzo.jpg"
                            title="Mahindra Marazzo"
                            distanceTraveled={1200}
                        />
                    </div>
                </div>
                <div>
                    <h3 className="text-2xl font-medium mb-5">Social Media</h3>
                    <div className="flex flex-wrap">
                        <Instagram />
                    </div>
                </div>
            </div>
            <div className="mb-8" id="contact">
                <h2 className="text-4xl font-semibold mb-5 md:mb-7">Contact</h2>
                <div className="md:text-lg">
                    <p className="mb-3">
                        Got a suggestion, feedback or a project you’d like to
                        collaborate on?
                    </p>
                    <p className="mb-5">Drop by and say a Hi!</p>

                    <p className="mb-2">
                        <OutboundLink
                            href="mailto:hello@thepadonwheels.com"
                            className="flex items-center"
                        >
                            <FiMail size="1.25rem" className="mr-1" />{" "}
                            hello@thepadonwheels.com
                        </OutboundLink>
                    </p>
                    <p>
                        <OutboundLink
                            href="https://www.instagram.com/thepadonwheels/"
                            className="flex items-center"
                            target="_blank"
                        >
                            <FiInstagram className="mr-1" size="1.25rem" /> DM @
                            thepadonwheels
                        </OutboundLink>
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
