import * as React from "react"

const posts = [
    {
        title: "Banaras, a city with uncontainable energy",
        link: "https://www.instagram.com/reel/CS_g78tliVC/embed/",
    },
    {
        title: "To see where the land ends...",
        link: "https://www.instagram.com/reel/CeDXBOYlIPX/embed/",
    },
    {
        title: "5 Prayags (confluences) of Uttarakhand",
        link: "https://www.instagram.com/reel/CcLLfEVF1ku/embed/",
    },
    {
        title: "One life. So many places to see!",
        link: "https://www.instagram.com/reel/CgweasqBaH4/embed/",
    },
    {
        title: "Best spots in India for a workation!",
        link: "https://www.instagram.com/p/CguGvQjh6EE/embed/",
    },
    {
        title: "Uttarakhand is a different vibe.",
        link: "https://www.instagram.com/reel/Cgoy2DthMG6/embed/",
    },
    {
        title: "The fallen bird.",
        link: "https://www.instagram.com/p/CgmmNX4BcXZ/embed/",
    },
    {
        title: "Cats of Kerala.",
        link: "https://www.instagram.com/p/CghfdK-BM6U/embed/",
    },
]
const Instagram = () =>
    posts.map((post, idx) => (
        <div
            key={idx}
            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 md:p-1 lg:p-2"
        >
            <iframe
                loading="lazy"
                className="w-full h-[45rem] shadow-md rounded-md p-2"
                title={post.title}
                src={post.link}
            />
        </div>
    ))

export default Instagram
