import * as React from "react"

import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => (
    <div className="min-h-screen flex flex-col px-1">
        <Header />
        <main className="container h-full flex-grow">{children}</main>
        <Footer />
    </div>
)

export default Layout
