import * as React from "react"

const PeopleCard = ({ title, subtitle, imageURL }) => (
    <div className="flex items-center mb-3 md:flex-col md:justify-center md:w-1/4">
        <div className="shadow-md w-20 h-20 rounded-full overflow-hidden mr-4 md:mr-0 md:mb-2">
            <img
                loading="lazy"
                src={imageURL}
                alt={`${title} of ThePadOnWheels`}
                className="w-full h-full object-cover"
            />
        </div>
        <div className="md:flex md:flex-col md:items-center">
            <h4 className="font-medium text-sm mb-1 md:text-lg">{title}</h4>
            <p className="uppercase text-xs md:text-sm">{subtitle}</p>
        </div>
    </div>
)

export default PeopleCard
